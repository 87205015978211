<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  box-sizing: border-box;
  height: 100%;
}
table td {
  padding: 5px 10px;
}
.activeModel {
  outline: 2px dashed #00afff;
}
.activeTarget {
  background-color: #f5f7f9 !important;
  color: red !important;
}
</style>
