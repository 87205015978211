import Vue from 'vue'
import VueRouter from 'vue-router'


const routes = [
    {
        path: '/printTemplate',
        name: 'panel',
        component: () => import('@/views/print-whole.vue'),
        children: [],
        meta: {
            title: '打印模板',
        },
    },
]
Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    // base: window.__MICRO_APP_BASE_ROUTE__ || '/',
    routes,
})
export default router